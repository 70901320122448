import React from 'react';

const defaultContext = {
  language: 'english',
  pages: [],
  mobileMenuOpen: false,
  loading: true,
  setLanguage: () => {},
  toggleLanguage: () => {},
  getLanguage: () => {},
  fetchPagesData: () => {},
  getPagesData: () => {},
  toggleMobileMenu: () => {},
  closeMobileMenu: () => {},
  isLoggedIn: false,
  setLoggedIn: () => {},
};

export const AppStateContext = React.createContext(defaultContext);

const isBrowser = typeof window !== 'undefined';

const localStorageKeys = {
  lang: 'ca.rocheterms.lang',
  pages: 'ca.rocheterms.pages',
};

const getLocalStorageItem = key => {
  return isBrowser ? window.localStorage.getItem(key) : null;
};

const setLocalStorageItem = (key, value) => {
  isBrowser && window.localStorage.setItem(key, value);
};

const getDefaultLanguage = () => {
  if (isBrowser) {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.startsWith('fr-')) {
      return 'french';
    }
  }

  return 'english';
};

export class AppStateProvider extends React.Component {

  constructor(props) {
    super(props);

    let language = getLocalStorageItem(localStorageKeys.lang);
    if (!language || !['english', 'french'].includes(language)) {
      language = getDefaultLanguage();
    }

    var pages = [];
    const pagesData = getLocalStorageItem(localStorageKeys.pages);
    if (pagesData) {
      try {
        pages = JSON.parse(pagesData);
      } catch (error) {
        console.error('Error parsing pages data:', error);
      }
    }

    this.state = {
      language: language,
      pages: pages,
      mobileMenuOpen: false,
      loading: pages.length === 0,
      isLoggedIn: false,
    };
    
    this.fetchData();
  }

  fetchData = async (retryCount = 3) => {
    const retryDelay = 500;
    try {
      const url = process.env.GATSBY_APP_API_URL;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ action: 'get' })
      };

      const response = await fetch(url, requestOptions);
      const responseData = await response.json();

      if (
        responseData &&
        responseData.pages &&
        Array.isArray(responseData.pages)
      ) {
        this.setState({
          pages: responseData.pages,
          loading: false,
          error: null
        });

        setLocalStorageItem(localStorageKeys.pages, JSON.stringify(responseData.pages));
      } else {
        if (retryCount > 0) {
          setTimeout(() => {
            this.fetchData(retryCount - 1);
          }, retryDelay);
        } else {
          throw new Error('Invalid or missing page data');
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (retryCount > 0) {
        setTimeout(() => {
          this.fetchData(retryCount - 1);
        }, retryDelay);
      } else {
        const localPagesData = JSON.parse(getLocalStorageItem(localStorageKeys.pages));
        if (localPagesData) {
          this.setState({
            pages: localPagesData,
            loading: false,
            error: null
          });
        } else {
          this.setState({
            pages: [],
            loading: false,
            error: 'Failed to fetch data. Retries exhausted.'
          });
        }
      }
    }
  };

  setLanguage = language => {
    if (!language || !['english', 'french'].includes(language)) {
      language = 'english';
    }

    this.setState({
      language: language
    });

    setLocalStorageItem(localStorageKeys.lang, language);
    this.loadLanguage(language);
  };

  loadLanguage = (language, retryCount = 0) => {
    if (isBrowser && window.OneTrust) {
      const langCode = language === 'french' ? 'fr-ca' : 'en';
      window.OneTrust.changeLanguage(langCode);
    } else if (retryCount < 10) {
      const loadLanguage = this.loadLanguage;
      setTimeout(function () {
        loadLanguage(language, retryCount + 1);
      }, (2 + retryCount) * 50);
    }
  };


  toggleLanguage = () => {
    const language = this.state.language === 'french' ? 'english' : 'french';
    this.setLanguage(language);    
  };  

  toggleMobileMenu = () => {
    const mobileMenuOpen = this.state.mobileMenuOpen === false ? true : false;
    this.setState({
      mobileMenuOpen: mobileMenuOpen
    });

    const html = document.getElementsByTagName('html')[0];
    if (mobileMenuOpen) html.style.overflow = 'hidden';
    else html.style.overflow = 'auto';

  };

  closeMobileMenu = () => {
    setTimeout(() => {
      this.setState({
        mobileMenuOpen: false
      });
    }, 50);
    const html = document.getElementsByTagName('html')[0];
    html.style.overflow = 'auto';
  };

  setLoggedIn = (loggedIn) => {
    this.setState({
      isLoggedIn: loggedIn,
    });
  };

  getValue = () => {
    return {
      ...this.state,
      setLanguage: this.setLanguage,
      toggleLanguage: this.toggleLanguage,
      fetchPagesData: this.fetchPagesData,
      toggleMobileMenu: this.toggleMobileMenu,
      closeMobileMenu: this.closeMobileMenu,
      setLoggedIn: this.setLoggedIn,
    };
  };

  render() {
    return (
      <AppStateContext.Provider value={this.getValue()}>
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
}

export function wrapRootElement({ element }) {
  return <AppStateProvider>{element}</AppStateProvider>;
}
